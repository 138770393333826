<template>
  <LiefengContent>
    <template v-slot:title>老人信息中心
      <span class="eye" @click.stop="changeEye">
        <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
      </span>
    </template>
    <template v-slot:toolsbarRight>
      <Form :model="searchForm" :inline="true" :label-width="60">
        <FormItem label="姓名">
          <Input
            v-model.trim="searchForm.name"
            :maxlength='10'
            placeholder="请输入姓名"
            style="width: 160px"
          ></Input>
        </FormItem>
        <FormItem label="手机号">
          <Input
            v-model.trim="searchForm.phone"
            :maxlength='20'
            placeholder="请输入手机号"
            style="width: 160px"
          ></Input>
        </FormItem>
        <FormItem label="状态">
          <Select v-model="searchForm.status" style="width: 160px">
            <Option value="0">全部</Option>
            <Option value="1">待处理资料未完善</Option>
            <Option value="2">待处理资料已完善</Option>
            <Option value="3">处理中</Option>
            <Option value="4">有结果</Option>
            <Option value="5">已过期</Option>
          </Select>
        </FormItem>
        <Button
          type="primary"
          icon="ios-search"
          @click="search"
          style="margin-right: 10px"
          >搜索</Button
        >
        <Button
          type="warning"
          icon="md-add"
          @click="addResult"
          style="margin-right: 10px"
          >新增结果</Button
        >
        <Button type="success" icon="ios-download-outline" @click="importExcel">导入</Button>
      </Form>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="page"
        @hadlePageSize="hadlePageSize"
        :loading="loading"
        :fixTable="true"
        :pagesizeOpts="[20, 30, 50, 100]"
        :pageSize="pageSize"
      ></LiefengTable>
      <!-- 新增 -->
      <LiefengModal
        :title="addChangeTitle"
        :fullscreen="true"
        :value="addChangeStatus"
        @input="addChangeStatusFn"
        class="addModal"
      >
        <template v-slot:contentarea>
            <AddChangeResult ref="addChangeResult" :addChangeIden='addChangeIden' :infoId="infoId" @saveSuccess="saveSuccess" v-if="addChangeStatus"></AddChangeResult>
        </template>
        <template v-slot:toolsbar>
          <Button
            @click="
              cancelModelTip = true;
              addChangeStatusFn(false);
            "
            type="info"
            style="margin-right: 10px"
            >取消</Button
          >
          <Button type="primary" @click="save">保存</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/pensionoldpeople');
import LiefengContent from "@/components/LiefengContent3";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import AddChangeResult from '@/views/pension/childrens/addChangeResult'
export default {
  data() {
    return {
      hasSensitive:true,
      addChangeIden: true,  //true代表新增
      searchForm: {},
      pageSize: 20,
      page: 1,
      total: 0,
      loading: false,
      tableData: [],
      talbeColumns: [
        {
          title: "姓名",
          key: "name",
          align: "center",
          width: 100,
        },
        {
          title: "性别",
          key: "sex",
          align: "center",
          width: 80,
        },
        {
          title: "手机号码",
          key: "phone",
          align: "center",
          width: 140,
        },
        {
          title: "出生日期",
          key: "birthday",
          align: "center",
          width: 140,
        },
        {
          title: "年龄",
          key: "age",
          align: "center",
          width: 80,
        },
        {
          title: "所属社区",
          key: "orgName",
          align: "center",
          minWidth: 160,
        },
        {
          title: "评估时间",
          key: "gmtCreate",
          align: "center",
          minWidth: 170,
        },
        {
          title: "到期时间",
          key: "dueDate",
          align: "center",
          minWidth: 170,
        },
        {
          title: "是否评估",
          key: "assessed",
          align: "center",
          width: 100,
        },
        {
          title: "状态",
          key: "status",
          align: "center",
          width: 160,
        },
        {
          title: "操作",
          width: 120,
          fixed: 'right',
          align: "center",
          render: (h, params) => {
            return h("div", [
              // h(
              //   "Button",
              //   {
              //     props: {
              //       type: "primary",
              //       size: "small",
              //     },
              //     style: {
              //       marginRight: "10px",
              //     },
              //     on: {
              //       click: () => {
              //         this.infoId = params.row.resultId;
              //         this.changeRow();
              //       }
              //     }
              //   },
              //   "修改资料"
              // ),
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                    disabled: params.row.resultId ? false : true
                  },
                  on: {
                    click: () => {
                      this.addChangeIden = false;
                      this.infoId = params.row.resultId;
                      this.changeRow();
                    }
                  }
                },
                "评估结果"
              ),
            ]);
          },
        },
      ],
    //   新增/修改
    addChangeTitle: '',
    addChangeStatus: false,
    cancelModelTip: true,
    infoId: '',
    };
  },
  methods: {
    // 导入
    importExcel() {
      this.$Message.info({
        background: true,
        content: "敬请期待"
      })
    },
    // 修改
    changeRow() {
      this.$Message.loading({
        duration: 0,
        content: "正在加载数据，请稍等..."
      });
      this.addChangeStatus = true;
    },
    //   新增
    addResult() {
      this.infoId = '';
        this.addChangeTitle = "新增老年人照顾需求等级评定报告";
        this.addChangeIden = true;
        this.addChangeStatus = true;
    },
    save() {
      this.$refs.addChangeResult.save();
    },
    saveSuccess() {
      this.cancelModelTip = false;
      this.addChangeStatus = false;
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      })
    },
    addChangeStatusFn(status) {
        if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.addChangeStatus = status;
          },
        });
      } else {
        this.addChangeStatus = status;
      }
    },
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    hadlePageSize(obj) {
        this.loading = true;
        let status = '';
        if(this.searchForm.status !== '0') {
          status = this.searchForm.status;
        }
      this.$get("/pension/api/pc/elderly/userElderly/queryUserElderlyByPage", {
        ...this.searchForm,
        status,
        page: obj.page,
        pageSize: obj.pageSize,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        hasSensitive:this.hasSensitive
      }).then((res) => {
            this.loading = false;
        if (res.code == 200 && res.dataList) {
          res.dataList.map(item => {
            if(item.birthday) {
              item.birthday = this.$core.formatDate(new Date(item.birthday),"yyyy-MM-dd");
              item.age = Math.ceil(
                    (new Date().getTime() - new Date(item.birthday).getTime()) /
                      31536000000
                  );
            }
            item.sex = item.sex == '0' ? '未知' : item.sex == '1' ? '男' : item.sex == '2' ? '女' : '';
            item.gmtCreate = item.gmtCreate ? this.$core.formatDate(new Date(item.gmtCreate),"yyyy-MM-dd") : '';
            item.dueDate = item.dueDate ? this.$core.formatDate(new Date(item.dueDate),"yyyy-MM-dd") : '';
            item.assessed = item.assessed == '0' ? '未评估' : item.assessed == '1' ? '已评估' : '';
            switch (item.status) {
              case '1': 
                item.status = '待处理资料未完善';
                break;
              case '2': 
                item.status = '待处理资料已完善';
                break;
              case '3': 
                item.status = '处理中';
                break;
              case '4': 
                item.status = '有结果';
                break;
              case '5': 
                item.status = '已过期';
                break;
            }
          })
            this.tableData = res.dataList;
            this.page = res.currentPage;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
        }else {
            this.$Message.error({
                background: true,
                content: res.desc
            })
        }
      });
    },
    changeEye(){
      this.hasSensitive = !this.hasSensitive
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
  },
  created() {
      this.hadlePageSize({
          page: this.page,
          pageSize: this.pageSize
      })
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
    AddChangeResult
  },
};
</script>
    
<style scoped lang='less'>
// /deep/#toolsbarRight {
//   width: 1000%;
//   .ivu-form-item {
//     margin-bottom: 0;
//   }
// }
.addModal {
  /deep/.ivu-icon-ios-close {
    display: none;
  }
}
.eye{
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin: 0 15px 0 ;
}
</style>